/* chart.css */
.apexcharts-menu {
    background: #333 !important;  /* Dark background */
    color: #fff !important;       /* White text */
}

.apexcharts-menu-item:hover {
    background: #444 !important;  /* Slightly lighter dark background on hover */
    color: #fff !important;
}

.apexcharts-menu-item {
    color: #fff !important;
}
